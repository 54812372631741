import React from 'react'

export const SVGCision = (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 3735.5 1038.1'>
    <path
      fill='#1399a4'
      d='M708.8 8.8v415.5L855 407.5V8.8H708.8M708.8 1029.5H855v-399l-146.2-16.7v415.7M1267.1 329.2c-1.5-17-2.3-34-2.1-51 0-16.6 1.4-33 4.3-49.4 2.5-14.8 7.5-28.6 15-41.6a83.6 83.6 0 0130.1-29.4 96 96 0 0149.5-11.4c37.3 0 63.8 12.1 79.6 36.5a156.2 156.2 0 0123.7 86.8v33h146.1V231c0-29.1-5.8-57.1-17.2-83.9a233.6 233.6 0 00-49.5-73.8 245.3 245.3 0 00-177-73.1c-42 0-78.8 7.4-110.3 22.2a219.7 219.7 0 00-78.2 59.5c-21.5 26.5-37 56.1-46.6 88.8a368.4 368.4 0 00-15.7 109c-.3 32.5 3 64.6 10 96.4l146.4-16.7c-4.4-9.7-7-19.8-8-30.3M1467.1 739.9c0 22.9-1 44-2.8 63-1.3 17-5.8 33-13.7 48a72.4 72.4 0 01-31.5 30.2c-13.9 7.2-33.3 10.7-58 10.7-37.3 0-63.8-10.7-79.6-32.2a118.7 118.7 0 01-23.7-72.4v-45.9h-146.1v54.5a237.3 237.3 0 00150.5 223.5c31.8 12.7 64.7 19 99 18.6 46.7 0 86.4-7.6 118.9-22.9 31.3-14.2 57.4-35 78.1-62.3a251 251 0 0042.3-94.6 524 524 0 0012.9-119.7c0-7.2 0-14.3-.3-21.1l-146.9-16.8c.6 11.7 1 24.8 1 39.4M1870 1029.5h146.2v-266l-146.3-16.8v282.8M1870 8.8v282.5l146.2-16.7V8.8h-146.3M2468.4 171.5a108.3 108.3 0 0188.3-23.5 108.6 108.6 0 0150.8 23.5c10.3 9 17.7 19.9 22.3 32.8l147-16.8a232 232 0 00-150.7-171.7 253.6 253.6 0 00-176.3 0 242.6 242.6 0 00-78.9 46.6c-24.5 22-43.4 48-56.6 78.1a256.7 256.7 0 00-21.3 102.3l147.8-17c3.5-23.5 12.7-41.7 27.6-54.4M2607.5 866.7a110.3 110.3 0 01-50.8 23.5 108.6 108.6 0 01-88.3-23.5c-15-12.7-24.2-31-27.7-54.6L2293 795c.7 39.3 7.8 73.7 21.3 103.2a226.8 226.8 0 0056.6 77.4 241.6 241.6 0 0079 46.6 254.1 254.1 0 00176.2 0c29.3-10.5 55.6-26 79-46.6a226.8 226.8 0 0056.5-77.3c7-15.3 12.1-31.2 15.5-47.7l-147.3-17c-4.5 13-11.9 24-22.3 33M3422.5 1029.5H3560v-89.2l-177.5-20.4 39.9 109.6M3049.8 1029.5H3196v-131l-146.2-16.7v147.7M3413.9 8.8v105.7L3560 97.8v-89H3414M3049.7 8.8v147.4l185.8-21.2-45.3-126.2h-140.5'
    />
    <path
      fill='#e76a37'
      d='M708.8 424.3v189.5L855 630.5v-223l-146.2 16.8M1585.4 561.4a175.6 175.6 0 00-46.6-58.8A276.7 276.7 0 001460 459l-111.7-44.5c-16-6-31-13.6-45.2-22.9a81.2 81.2 0 01-25-26.5l-3-5.6-146.3 16.8c4.8 22 12.2 43.1 22.2 63.3 21.5 42.6 63.8 76.8 126.9 102.5l104.6 43c15.1 6.2 29.7 13.3 43.8 21.5a73.5 73.5 0 0125.7 25.8 105.3 105.3 0 0112.2 41.6c.9 7.8 1.5 16.7 2 26.5l146.8 16.9c-.4-26.3-2.2-52.5-5.4-78.5-3.2-27-10.6-52.8-22.2-77.4M1870 291.3v455.4l146.2 16.8v-489l-146.3 16.8M2783.1 249.6c.1-21-2-41.6-6.3-62.1l-147 16.8c5 14.7 7.4 29.8 7.1 45.3v539c.3 15.5-2 30.5-7 45.1l147.2 17c4.1-20.5 6.1-41.2 6-62V249.6M2439 788.6v-539c0-8 .6-15.8 1.8-23.7L2293 243v552.3l147.8 16.9c-1.2-7.8-1.7-15.6-1.7-23.5M3413.9 114.6v509.2h-2.9L3235.5 135l-185.7 21.2v725.6l146.2 16.8V415.9h2.9l183.7 504 177.5 20.4V97.8L3414 114.6'
    />
    <path
      fill='#1399a4'
      d='M405.5 71c-21.4-21.8-46-39-73.8-51.6a213.6 213.6 0 00-91-19.4c-28.7.2-57 4-84.6 11.5a203.5 203.5 0 00-77.3 39.4 213 213 0 00-56.6 73.9C7.4 155.3 0 194 0 240.8v556.4c0 46.9 7.4 85.6 22.2 116.1a202.6 202.6 0 00134 113.2 326.5 326.5 0 0084.6 11.5c31.7.3 62-6.2 91-19.3a242.6 242.6 0 0073.8-51.6c21.4-22 37.8-47 49.5-75.3 12-28.9 18-59 17.9-90.3v-92.1l-147.7-25.2v44.7c0 30 .4 98.4-16.7 125.3-4 6.6-9 12.5-14.7 17.8a83.5 83.5 0 01-28 14.8c-7.5 2.4-15.2 4-23 4.7-2.2 0-4.5.3-6.8.3h-1c-33.5 0-56.6-10-69.5-30.1-12.9-20.1-19.4-45-19.4-74.5V250.8c0-29.6 6.5-54.4 19.4-74.5 12.9-20 36-30 69.5-30h1c2.3 0 4.5 0 6.7.2 7.8.8 15.5 2.4 23 4.7a83.6 83.6 0 0128 14.9 86.2 86.2 0 0114.7 17.7c17.1 27 16.6 95.3 16.6 125.4v44.7l147.8-25.2v-92.2c.1-31.3-5.9-61.4-17.9-90.3A234.9 234.9 0 00405.5 71M3633.2 60a50.4 50.4 0 0131.2-47.3 50.7 50.7 0 0156.2 10.9 51.2 51.2 0 11-87.4 36m92.1 0a42 42 0 00-25-38.5 39.7 39.7 0 00-31.9 0 41 41 0 00-21.7 22.3 42 42 0 0037.7 58 39.7 39.7 0 0029-12.2 42.2 42.2 0 0012-29.6M3665 29.8h22.6c13.7 0 20.5 5.6 20.5 16.8 0 2.5-.3 5-1.2 7.2a13.6 13.6 0 01-8.2 8c-1.8.7-3.7 1.2-5.7 1.5l17.2 26.2h-11.3l-17.2-25.8h-7.2v25.8h-9.3zm9.3 25.3h10.6c3.7.2 7.1-.4 10.4-2a6.8 6.8 0 003.5-6.9c.2-2.6-.8-4.6-3-6-2.5-1.4-5.1-2-7.9-1.9h-13.6v16.8'
    />
  </svg>
)
