import React from 'react'

export const SVGZaarly = (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 336 120'>
    <path
      fill='#03a678'
      d='M282.4 117.9c14.4 0 25-7.1 31.6-26.2l11.5-32.8c4.6-13.2 9.6-25.8 10.5-28h-18.5L312.4 48c-2.6 8.2-5 16.3-7.5 23.1-2-6.3-3.7-13-6.1-22.3-1.5-6.2-3.1-12.4-4-17.8h-20l9.5 29.4c3.9 12.2 7.3 24.5 7.7 27h6.4l-.8 2.7c-3.7 11.1-8.9 14-15.2 14-3.1 0-6.2-.8-8.6-2.4l-3.6 13.4c2.6 1.4 7.2 2.8 12.2 2.8m-16.6-82.2c0-17.9-.1-28.6-.7-34.3h-19.5c1.1 6.3 1.9 16.3 1.9 33.5v35.7c0 11.4 3.7 20.6 15.9 20.6 3.4 0 6.5-.8 9.3-1.9l.8-11.5c-1.4.6-2.3.8-3.1.8-2.7 0-4.6-2.4-4.6-9V35.7zm-43.9 27.6c0-11.3 5.9-17 15-17 1.1 0 2.1.1 3.2.2l.2-18.4V28c-8.9 0-15.1 5.9-18.9 14.9v-12h-18c.2 2.9.7 17.9.7 30.5 0 12.5-.1 21.1-.7 28.8h18.9c-.2-2.8-.4-13.9-.4-26.9m-34.5 27.9c3 0 6.1-.8 8.8-1.9l.8-10.4c-1.2.4-2.3.5-2.9.5-2.1 0-3.3-2.3-3.3-8.7V51.9c0-16.8-11.4-23.5-27.2-23.5-10.2 0-18.7 2.8-24.1 8.4l5.5 12.4c4.2-4.1 9.2-5.8 15-5.8 8.2 0 13.2 3.1 13.2 10.8v1.3c-3.2-1.7-6.8-2.8-12.1-2.8-14 0-23.5 8.1-23.5 20.9 0 11 8.4 18.4 20.1 18.4 7.5 0 14.1-3.6 17.5-9 1.9 5 5.6 8.2 12.2 8.2m-24.7-11.1c-5.1 0-8.8-2.8-8.8-7.9 0-5.2 3.9-9.1 10.6-9.1 2.9 0 6.1.6 8.7 1.7v5.3c-.1 5.6-4 10-10.5 10m-39.6 11.1c3 0 6.1-.8 8.8-1.9l.8-10.4c-1.2.4-2.3.5-2.9.5-2.1 0-3.3-2.3-3.3-8.7V51.9c0-16.8-11.4-23.5-27.2-23.5-10.2 0-18.7 2.8-24.1 8.4l5.5 12.4c4.2-4.1 9.2-5.8 15-5.8 8.2 0 13.2 3.1 13.2 10.8v1.3c-3.2-1.7-6.8-2.8-12.1-2.8-14 0-23.5 8.1-23.5 20.9 0 11 8.4 18.4 20.1 18.4 7.5 0 14.1-3.6 17.5-9 1.8 5 5.5 8.2 12.2 8.2M98.4 80.1c-5.1 0-8.8-2.8-8.8-7.9 0-5.2 3.9-9.1 10.6-9.1 2.9 0 6.1.6 8.7 1.7v5.3c-.1 5.6-4 10-10.5 10m-63.1 11c11.5 0 25.6-.2 33.5.1 0 0-.9-10.6-1.2-15.3-7.1.2-16.6.4-27.3.4h-13c14.3-20.8 27.4-41.9 42.3-61.8l-1.5-9c-8.4.4-17.9.5-33.3.5-14.8 0-20-.1-31-.5 0 5.8-.1 10.9-.5 15.7 7.8-.1 15.5-.4 25.6-.4h13.6C28.3 41.3 15 61.6 0 82.8l1.4 9c8.7-.6 20.5-.7 33.9-.7'
    ></path>
  </svg>
)
