import React from 'react'

export const SVGQuote = (
  <svg viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.00404 17.9739C0.829481 11.0008 6.32731 5.1997 13.2998 5C14.2001 5 14.93 5.72987 14.93 6.63021C14.93 7.53056 14.2001 8.26043 13.2998 8.26043C10.1049 8.3058 7.17893 10.0584 5.63125 12.8537C5.58862 12.9272 5.57633 13.0143 5.59697 13.0967C5.64075 13.2713 5.81784 13.3774 5.9925 13.3337C6.46796 13.2137 6.9563 13.1524 7.44665 13.1511H7.44926C11.0089 13.1511 13.8945 16.0367 13.8945 19.5963C13.8945 23.1559 11.0089 26.0415 7.44926 26.0415C3.88967 26.0415 1.00404 23.1559 1.00404 19.5963L1.00404 19.595V17.9739ZM31 6.63021C31 7.53056 30.2701 8.26043 29.3698 8.26043C26.1749 8.3058 23.2489 10.0584 21.7013 12.8537C21.6363 12.9674 21.645 13.1088 21.7234 13.2137C21.802 13.3183 21.9356 13.3656 22.0625 13.3337C22.5384 13.2136 23.0272 13.1523 23.518 13.1511H23.5193C27.0789 13.1511 29.9645 16.0367 29.9645 19.5963C29.9645 23.1559 27.0789 26.0415 23.5193 26.0415C19.9597 26.0415 17.0741 23.1559 17.0741 19.5963V19.595V17.9739C16.9002 11.001 22.3976 5.2004 29.3698 5C30.2701 5 31 5.72987 31 6.63021Z'
      fill='var(--color-text)'
    />
  </svg>
)
